body {
  font-family: 'Montserrat', Arial, sans-serif;
  font-family: 'PT Mono', Arial, sans-serif;
  font-size: 13px;
}


@keyframes shadow-pulse-big
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 70px rgba(0, 0, 0, 0);
  }
}

.pulse {
  animation: shadow-pulse 1s infinite;
}